@font-face {
  font-family: "Tiempos Headline";
  src: url("../fonts/TiemposHeadline-Light.woff2") format("woff2"),
    url("../fonts/TiemposHeadline-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tiempos Headline";
  src: url("../fonts/TiemposHeadline-Bold.woff2") format("woff2"),
    url("../fonts/TiemposHeadline-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("../fonts/Moderat-Regular.woff2") format("woff2"),
    url("../fonts/Moderat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Moderat";
  src: url("../fonts/Moderat-Bold.woff2") format("woff2"),
    url("../fonts/Moderat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0px;
  margin: 0px;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: "Moderat";
  font-weight: normal;
  font-size: 20px;
  color: #404751;
}

body,
html {
  background-color: #f4f3f0;
  scroll-behavior: smooth;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}

@media (max-width: 1350px) {
  .desktopCustom {
    display: none;
  }
}

.tooltip {
  border-radius: 12px !important;
  background: #404751 !important;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 16px 24px !important;
  max-width: 370px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px !important;
  font-weight: 700;
  line-height: normal;
  z-index: 999;
}

.tooltip a {
  color: #fff;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px !important;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
}

.tooltip a:hover {
  text-decoration: underline;
}

.sticky {
  position: fixed !important;
  top: 80px;
  padding-bottom: 16px;
  /* padding-top: 32px; */
}

.abs {
  position: absolute !important;
  bottom: 0px;
}
